import { Marker } from "@react-google-maps/api";
import { getAllTasks } from "apis/driverClient";
import { useEffect, useState } from "react";
import DriverPositionMarker from "../../../../assets/images/map/driver_position.svg"

const DriverPosition = ({ driver, npiKey, filteredDate }) => {
    const [driverLocation, setDriverLocation] = useState([]);

    async function fetchTasksData() {
        if (driver && filteredDate && npiKey) {
            const response = await getAllTasks(
                driver.onfleet_worker_id,
                //'ujbAoULLe~epp9z2owjQMTgM', test purpose
                npiKey,
                `${filteredDate} 00:00:00`,
                `${filteredDate} 23:59:59`);
            if (response.metadata.location) {
                setDriverLocation(response.metadata.location);
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            fetchTasksData();
        }, 500)
    }, [driver, filteredDate, npiKey]);

    return driverLocation.length > 0 && (
        <Marker
            position={{
                lng: parseFloat(driverLocation[0]),
                lat: parseFloat(driverLocation[1]),
            }}
            icon={{
                url: DriverPositionMarker,
            }}
        >
        </Marker>
    )
}

export default DriverPosition;