import React, { useCallback, useState, useEffect } from "react";
import GreenMarker from "../../../../assets/images/map/succeeded.svg"
import RedMarker from "../../../../assets/images/map/failed.svg"
import BlueMarker from "../../../../assets/images/map/inTransit.svg"
import PurpleMarker from "../../../../assets/images/map/assigned.svg"
import { InfoWindow, Marker, Polyline } from "@react-google-maps/api";
import CloseIcon from '@mui/icons-material/Close';
import { styled, Typography } from "@mui/material";

const InfoWindowData = styled(Typography)(() => ({
    display: "flex",
    justifyContent: "space-between",
    padding: "0px",
    fontSize: 12,
}));

const DirectionsFlightPath = ({ task, taskIndex }) => {
    const departurePosition = {
        lat: parseFloat(task.departure_lat),
        lng: parseFloat(task.departure_long),
    };
    const arrivalPosition = {
        lat: parseFloat(task.arrival_lat),
        lng: parseFloat(task.arrival_long),
    };

    const [infoWindowShown, setInfoWindowShown] = useState(false);
    const [marker, setMarker] = useState(PurpleMarker);

    useEffect(() => {
        // if (task.status === 0) {
        //     setMarker(PurpleMarker);
        // } else if (task.status === 1) {
        //     setMarker(GreenMarker);
        // } else if (task.status === 2) {
        //     setMarker(RedMarker);
        // }
        if (task.state === 2) {
            setMarker(BlueMarker);             
        } else if (task.state === 1) {
            setMarker(PurpleMarker);
        } else if (task.state === 3) {
            if (task.status === 1) {
                setMarker(GreenMarker);
            } else if (task.status === 2) {
                setMarker(RedMarker);
            }
        }
    }, [task.status]);

    const handleMarkerClick = useCallback(
        () => setInfoWindowShown(isShown => !isShown),
        []
    );

    const handleClose = useCallback(() => setInfoWindowShown(false), []);

    return (
        <div>
            {/* Test if this is the first task, then draw a marker and a polyline */}
            {taskIndex === 0 && task.departure_lat && task.departure_long ? (
                <div>
                    <Polyline
                        path={[
                            departurePosition,
                            arrivalPosition,
                        ]}
                        options={{
                            strokeColor: "#041967",
                            //strokeOpacity: 0.5,
                            strokeWeight: 2,
                            geodesic: true,
                            icons: [{
                                // eslint-disable-next-line no-undef
                                icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                                offset: '50%'
                            }]
                        }}
                    />
                    <Marker
                        position={departurePosition}
                        icon={{
                            url: BlueMarker,
                        }}
                    >
                    </Marker>
                </div>

            ) : null}

            {/* Based on parent's map method, draw all marker and each of them has info window which show data */}
            <Marker
                position={arrivalPosition}
                icon={{
                    url: marker,
                }}
                label={{
                    text: `${taskIndex + 1}`,
                    fontSize: "8px",
                    color: "#FFFFFF",
                }}
                onClick={() => handleMarkerClick()}
            >
                {infoWindowShown && (
                    <InfoWindow onCloseClick={handleClose}>
                        <div>
                            <InfoWindowData>
                                <p>{task.recipient_name}</p>
                                <CloseIcon
                                    onClick={() => handleClose()}
                                    style={{ cursor: "pointer" }}
                                />
                            </InfoWindowData>
                            <Typography>{task.destination_address}</Typography>
                        </div>
                    </InfoWindow>
                )}
            </Marker>
        </div>
    );
}

export default DirectionsFlightPath;
